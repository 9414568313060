import React from "react";
import Amplify from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignOut, AmplifySignIn } from "@aws-amplify/ui-react";


let Auth = {
  region: 'us-east-1',
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  mandatorySignIn: true,
  authenticationFlowType: 'USER_SRP_AUTH',
  oauth: {
    //domain: process.env.REACT_APP_DOMAIN,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
    responseType: 'token'
  }
}


Amplify.configure({ Auth })

// const App = () => (

//   < AmplifyAuthenticator >
//     <div>
//       My App Dev Version 1
//       <AmplifySignOut />
//     </div>
//   </AmplifyAuthenticator >
// );

// export default App;

function App() {
  return (
    <AmplifyAuthenticator>
      <div className="App" slot="sign-in" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>

        <AmplifySignIn headerText="Labels Cloud" hideSignUp />

      </div>
      <div>
        My App Dev Version 1
        <AmplifySignOut />
      </div>
    </AmplifyAuthenticator>
  );
}

export default App;